<template>
  <ul v-if="nodes">
    <li
      v-for="node in theNodes"
      :key="node.label"
      :class="{
        parent_li: isParentNode(node),
        'mb-5': isFirst,
      }"
    >
      <div :class="{ active: node.intentId === intentId }">
        <span>
          <b-icon-dash-square
            v-if="node.expand !== false && isParentNode(node)"
            class="mr-2 expand-icon"
            @click="(e) => expand(e, node)"
          />
          <b-icon-plus-square
            v-if="node.expand === false && isParentNode(node)"
            class="mr-2 expand-icon"
            @click="(e) => expand(e, node)"
          />
          {{ node.label }}
        </span>
        <b-icon-pencil-square
          @click="(e) => nodeClicked(e, node)"
          class="ml-2 edit-icon"
        />
      </div>

      <tree-child
        v-if="node.expand !== false && isParentNode(node)"
        :nodes="node.children"
        :intent-id="intentId"
        :is-first="false"
        @node-clicked="nodeClicked"
      />
    </li>
  </ul>
</template>

<script>
/*global _*/
export default {
  name: 'TreeChild',
  props: {
    nodes: { type: Array },
    isFirst: { type: Boolean, default: true },
    intentId: { type: Number, default: 0 },
  },
  data() {
    return {
      theNodes: [
        {
          label: 'error',
          intentId: -1,
          expand: true,
          children: [],
        },
      ],
    };
  },
  mounted() {
    this.theNodes = this.nodes;
  },
  watch: {
    nodes() {
      this.theNodes = this.nodes;
    },
  },
  methods: {
    isParentNode(node) {
      return (
        Object.prototype.hasOwnProperty.call(node, 'children') &&
        node.children.length
      );
    },
    nodeClicked(e, data) {
      this.$emit('node-clicked', e, data);
    },
    expand(e, node) {
      const list = [...this.theNodes];
      const expand = Object.prototype.hasOwnProperty.call(node, 'expand')
        ? !node.expand
        : false;
      const newNode = {
        ...node,
        ...{ expand: expand },
      };
      const index = _.indexOf(list, node);
      list.splice(index, 1, newNode);
      this.theNodes = list;

      // if (e.target.classList.contains('edit-icon')) {
      //   return;
      // }
      // if (e.target.parentNode.parentNode.className === 'parent_li') {
      //   const list = [...this.theNodes];
      //   const expand = Object.prototype.hasOwnProperty.call(node, 'expand') ? !node.expand : false;
      //   const newNode = {
      //     ...node,
      //     ...{ expand: expand },
      //   };
      //   const index = _.indexOf(list, node);
      //   list.splice(index, 1, newNode);
      //   this.theNodes = list;
      // }
    },
  },
};
</script>

<style scoped>
.tree {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #fbfbfb;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.tree li {
  list-style-type: none;
  margin: 0;
  padding: 10px 5px 0 5px;
  position: relative;
}

.tree li::before,
.tree li::after {
  content: '';
  left: -20px;
  position: absolute;
  right: auto;
}

.tree li::before {
  border-left: 1px solid #999;
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}

.tree li::after {
  border-top: 1px solid #999;
  height: 20px;
  top: 30px;
  width: 25px;
}

.tree li div {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border: 1px solid #999;
  border-radius: 5px;
  display: inline-block;
  padding: 3px 8px;
  text-decoration: none;
}

/*.tree li.parent_li > div {*/
/*  cursor: pointer;*/
/*}*/

.tree > ul > li::before,
.tree > ul > li::after {
  border: 0;
}

.tree li:last-child::before {
  height: 30px;
}

.tree li.parent_li > div.active,
.tree li.parent_li > div.active + ul li div,
.tree li.parent_li > div:hover,
.tree li.parent_li > div:hover + ul li div {
  background: #eee;
  border: 1px solid #94a0b4;
  color: #000;
}

.expand-icon {
  cursor: pointer;
  z-index: 100;
}

.expand-icon:hover {
  color: #0b93d5;
}

.edit-icon {
  cursor: pointer;
  z-index: 100;
}

.edit-icon:hover {
  color: #0b93d5;
}
</style>