var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-inline-block"},[_c('b-button',{staticClass:"list-button",attrs:{"variant":"outline-primary","size":"sm","title":"show history"},on:{"click":_vm.getActivityLogs}},[_c('b-icon',{staticClass:"list-button",attrs:{"icon":"clock-history"}})],1),_c('b-modal',{attrs:{"size":"lg","centered":"","scrollable":"","hide-footer":""},model:{value:(_vm.modalList),callback:function ($$v) {_vm.modalList=$$v},expression:"modalList"}},[(_vm.list.length)?_c('b-table-lite',{attrs:{"items":_vm.items,"fields":_vm.fields,"bordered":"","striped":""},scopedSlots:_vm._u([{key:"cell(actionDatetime)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("datetimelong")(row.value))+" ")]}},{key:"cell(showDetails)",fn:function(row){return [(row.item.action === 'Edit')?_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm"},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? 'Hide' : 'Show')+" Details ")]):_vm._e()]}},{key:"row-details",fn:function(row){return [_vm._l((row.item.comparedObj),function(change){return _c('b-row',{key:change.key},[_c('b-col',[_c('small',{staticClass:"mr-2 d-block"},[_vm._v(_vm._s(change.key))]),_c('small',{staticClass:"mr-2 text-warning"},[_vm._v("["+_vm._s(change.old)+"]")]),_c('b-icon-arrow-right',{staticClass:"mr-2"}),_c('small',{staticClass:"mr-2 text-success"},[_vm._v("["+_vm._s(change.new)+"]")])],1)],1)}),(
            row.item.intentTrainings.removed.length ||
            row.item.intentTrainings.added.length
          )?_c('div',[_c('div',[_c('small',[_vm._v("Questions")])]),_c('div',[_c('small',{staticClass:"text-muted pl-2"},[_vm._v("added:")])]),_c('div',_vm._l((row.item.intentTrainings.added),function(sentence){return _c('small',{key:sentence,staticClass:"d-block text-success pl-3"},[_vm._v(" "+_vm._s(sentence)+" ")])}),0),_c('div',[_c('small',{staticClass:"text-muted pl-2"},[_vm._v("removed:")])]),_c('div',_vm._l((row.item.intentTrainings.removed),function(sentence){return _c('small',{key:sentence,staticClass:"d-block text-warning pl-3"},[_vm._v(" "+_vm._s(sentence)+" ")])}),0)]):_vm._e(),(
            row.item.intentAnswers.removed.length ||
            row.item.intentAnswers.added.length
          )?_c('div',[_c('div',[_c('small',[_vm._v("Answers")])]),_c('div',[_c('small',{staticClass:"text-muted pl-2"},[_vm._v("added:")])]),_c('div',_vm._l((row.item.intentAnswers.added),function(answer){return _c('small',{key:JSON.stringify(answer),staticClass:"d-block text-success pl-3"},[_c('json-viewer',{attrs:{"value":answer}})],1)}),0),_c('div',[_c('small',{staticClass:"text-muted pl-2"},[_vm._v("removed:")])]),_c('div',_vm._l((row.item.intentAnswers.removed),function(answer){return _c('small',{key:JSON.stringify(answer),staticClass:"d-block text-warning pl-3"},[_c('json-viewer',{attrs:{"value":answer}})],1)}),0)]):_vm._e()]}}],null,false,920043490)}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }