<template>
  <div>
    <b-row>
      <b-col>
        <image-handler
          class="ml-3"
          :customPreviewSettings="{ rounded: true }"
          :image.sync="image"
          :maxDimensions="{ width: 1280, height: 1280 }"
        />
      </b-col>
    </b-row>
    <div class="p-3">
      <b-row class="form-group">
        <b-col cols="12" md="4" lg="3">
          <label class="control-label">Image Url</label>
        </b-col>
        <b-col v-if="image.fileBytes">
          <b-form-input v-model="useUploadedImage" disabled size="sm" />
        </b-col>
        <b-col v-else>
          <b-form-input
            type="url"
            v-model="value.content.url"
            size="sm"
            required
          />
        </b-col>
      </b-row>
      <b-row class="form-group">
        <b-col cols="12" md="4" lg="3">
          <label class="control-label">Description</label>
        </b-col>
        <b-col>
          <b-form-input v-model="value.content.description" size="sm" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageHandler from '@/components/ImageHandler';

export default {
  /*global _*/
  components: {
    ImageHandler,
  },
  props: ['value', 'imagename', 'imagebytes'],
  data() {
    return {
      answerObj: {
        type: 'image',
        content: {
          url: 'https://',
          description: '',
        },
      },
      defaultAnswer: {
        type: 'image',
        content: {
          url: 'https://',
          description: '',
        },
      },
      useUploadedImage: 'Use the uploaded image',
      image: {
        fileName: null,
        fileBytes: null,
        fileUrl: null,
        originalFileUrl: null,
      },
    };
  },
  mounted() {
    if (this.value.type !== 'image') {
      this.$emit('input', _.cloneDeep(this.defaultAnswer));
    } else {
      this.image = {
        fileName: this.image.fileName,
        fileBytes: this.image.fileBytes,
        fileUrl: this.value.content.url,
        originalFileUrl: this.image.originalFileUrl || this.value.content.url,
      };
    }
  },
  watch: {
    value: {
      handler(newVal) {
        if (newVal.type === 'image') {
          this.image = {
            fileName: this.image.fileName,
            fileBytes: this.image.fileBytes,
            fileUrl: this.image.fileUrl || newVal.content.url,
            originalFileUrl: this.image.originalFileUrl || newVal.content.url,
          };

          newVal.type = 'image';
          this.$emit('input', newVal);
        } else {
          this.$emit('input', _.cloneDeep(this.defaultAnswer));
        }
      },
      deep: true,
    },
    image(newVal) {
      this.image.originalFileUrl =
        newVal.originalFileUrl || this.value.content.url;
      this.value.fileName = newVal.fileName;
      this.value.fileBytes = newVal.fileBytes;
    },
  },
  methods: {},
};
</script>

<style>
.trumbowyg-box {
  margin: 0 !important;
}
</style>
