<template>
  <div>
    <b-overlay :show="isLoadingForm">
      <div v-if="formItem.intentType === 'system'">
        <b-alert show variant="warning"
          >This is a system message. Please edit from
          <b-link :to="{ name: 'system-messages' }"> System Messages</b-link>
        </b-alert>
      </div>
      <div v-else>
        <b-row>
          <b-col>
            <b-badge
              v-if="formItem.intentId"
              class="mr-1 info-badge"
              variant="primary"
              >ID: {{ formItem.intentId }}
            </b-badge>
            <b-badge
              v-if="formItem.intentName"
              class="mr-1 info-badge"
              variant="primary"
              >{{ formItem.intentName }}
            </b-badge>
            <div class="d-inline">
              <b-badge
                class="mr-1 info-badge"
                variant="secondary"
                v-for="(sentence, index) in sentenceBadges"
                :key="index"
                :title="sentence"
                >{{ sentence }}
              </b-badge>
            </div>
            <div class="d-inline">
              <b-badge
                class="mr-1 info-badge"
                variant="info"
                v-for="(answer, index) in answerBadges"
                :key="index"
                >{{ answer }}
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <b-tabs
          class="mt-2"
          content-class="mt-3"
          @activate-tab="resetQuestions"
        >
          <b-tab
            ref="main-tab"
            title="Settings"
            title-link-class="text-primary"
          >
            <b-form ref="main-form" @submit.stop.prevent="submit">
              <b-row v-if="formItem.intentId" class="form-group">
                <b-col cols="12" md="6" lg="4">Intent Id</b-col>
                <b-col> {{ formItem.intentId }}</b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12" md="6" lg="4">
                  <label class="control-label" for="intent-category"
                    >Intent Category</label
                  >
                </b-col>
                <b-col>
                  <b-select
                    id="intent-category"
                    :options="intentCategories"
                    v-model="formItem.intentCategoryId"
                    size="sm"
                    required
                  />
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12" md="6" lg="4">
                  <label for="intent-name">Intent Name</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="intent-name"
                    autofocus
                    v-model="formItem.intentName"
                    size="sm"
                    required
                  />
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12" md="6" lg="4">
                  <label class="control-label" for="intent-type"
                    >Intent Type</label
                  >
                </b-col>
                <b-col>
                  <b-select
                    id="intent-type"
                    v-model="formItem.intentType"
                    size="sm"
                    required
                  >
                    <b-select-option value="user">User</b-select-option>
                  </b-select>
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12" md="6" lg="4">
                  <label class="control-label" for="require-authentication"
                    >Require authentication?</label
                  >
                </b-col>
                <b-col>
                  <b-form-checkbox
                    id="require-authentication"
                    v-model="formItem.requireAuthentication"
                    :value="true"
                    :unchecked-value="false"
                  />
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12" md="6" lg="4">
                  <label class="control-label" for="notify-agent"
                    >Inform agent</label
                  >
                </b-col>
                <b-col>
                  <b-form-checkbox
                    id="notify-agent"
                    v-model="formItem.notifyAgent"
                    :value="true"
                    :unchecked-value="false"
                  />
                </b-col>
              </b-row>
              <b-row class="form-group">
                <b-col cols="12">
                  <label class="control-label">Additional Information</label>
                </b-col>
                <b-col class="form-group">
                  <custom-values v-model="formItem.customValues" />
                </b-col>
              </b-row>
            </b-form>
          </b-tab>

          <b-tab ref="questions-tab" title-link-class="text-secondary">
            <template #title>
              <span>Questions</span
              ><span class="ml-1" v-if="formItem.intentTrainings"
                >({{ formItem.intentTrainings.length }})</span
              >
            </template>
            <b-form ref="questions-form" @submit.stop.prevent="submit">
              <b-row class="">
                <b-col cols="12">
                  <label class="control-label">Questions</label>
                  <span
                    class="ml-2 clickable text-primary"
                    @click="
                      questionSearchMode = !questionSearchMode;
                      questionText = formItem.intentName;
                      getSuggestions();
                    "
                    >({{
                      questionSearchMode
                        ? 'close recommendations'
                        : 'use recommendations'
                    }})</span
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-row>
                    <b-col :cols="questionSearchMode ? 6 : 12">
                      <b-row
                        v-for="(question, index) in formItem.intentTrainings"
                        :key="index"
                      >
                        <b-col>
                          <b-form-input
                            v-model="question.sentence"
                            size="sm"
                            class="mb-1"
                            required
                          />
                        </b-col>
                        <b-col cols="1 px-1">
                          <b-badge
                            v-if="question.sentenceScore"
                            variant="secondary"
                            >{{ displayQuestionScore(question.sentenceScore) }}
                          </b-badge>
                        </b-col>
                        <b-col cols="1 px-1">
                          <b-icon-x
                            v-if="formItem.intentTrainings.length > 1"
                            class="clickable"
                            size="sm"
                            @click="
                              formItem.intentTrainings.splice(
                                formItem.intentTrainings.indexOf(question),
                                1
                              )
                            "
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col>
                          <b-button
                            variant="light"
                            class="text-primary border-primary mr-2"
                            size="sm"
                            @click="
                              formItem.intentTrainings.push({ sentence: '' })
                            "
                          >
                            <b-icon-plus />
                            Add question
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      cols="6"
                      class="search-question-container"
                      v-if="questionSearchMode"
                    >
                      <div class="search-question-panel">
                        <b-overlay :show="isLoadingSuggestions">
                          <b-row class="form-group">
                            <b-col>
                              <b-input-group>
                                <b-form-input
                                  v-model="questionText"
                                  placeholder="search term"
                                  autofocus
                                  size="sm"
                                  @keypress.enter="getSuggestions"
                                />
                                <b-input-group-append>
                                  <b-button
                                    size="sm"
                                    variant="outline-info"
                                    @click="getSuggestions"
                                    >search
                                  </b-button>
                                </b-input-group-append>
                              </b-input-group>
                            </b-col>
                          </b-row>
                          <b-row
                            v-if="questionSuggestions.length"
                            class="form-group"
                          >
                            <b-col>
                              <small class="text-muted"
                                >suggested questions:</small
                              >
                            </b-col>
                          </b-row>
                          <b-row
                            v-for="suggestion in questionSuggestions"
                            :key="suggestion"
                          >
                            <b-col>
                              <span
                                class="clickable mr-2"
                                @click="addQuestion(suggestion)"
                              >
                                <b-icon-plus-square
                                  shift-v="5"
                                  variant="primary"
                                />
                              </span>
                              <span
                                class="d-inline-block text-truncate"
                                style="max-width: calc(100% - 50px)"
                                v-b-tooltip.hover.v-secondary
                                :title="suggestion"
                                >{{ suggestion }}</span
                              >
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </b-tab>

          <b-tab ref="answers-tab" title="Answers" title-link-class="text-info">
            <b-form ref="answers-form" @submit.stop.prevent="submit">
              <b-row>
                <b-col>
                  <label class="control-label">Answer</label>
                </b-col>
              </b-row>
              <div
                v-for="(answer, index) in formItem.intentAnswers"
                :key="index"
              >
                <b-card class="mb-3">
                  <div class="form-group">
                    <b-button
                      v-if="formItem.intentAnswers.length > 1"
                      variant="light"
                      class="remove-button float-right"
                      size="sm"
                      @click="removeIntentAnswer(answer)"
                    >
                      <b-icon-x />
                    </b-button>
                    <b-tabs v-model="answer.tabIndex" lazy content-class="mt-3">
                      <b-tab
                        title="Text"
                        :disabled="
                          answer.answerType === 2 || answer.answerType === 3
                        "
                        :active="answer.answerObj.type === 'text'"
                        @click="changeAnswerType('text', answer)"
                      >
                        <answer-text
                          :answer-text.sync="answer.answerObj"
                          :intents="intents"
                        ></answer-text>
                      </b-tab>
                      <b-tab
                        title="Image"
                        :disabled="
                          answer.answerType === 2 || answer.answerType === 3
                        "
                        :active="answer.answerObj.type === 'image'"
                        @click="changeAnswerType('image', answer)"
                      >
                        <answer-image v-model="answer.answerObj"></answer-image>
                      </b-tab>
                      <b-tab
                        title="Video"
                        :disabled="
                          answer.answerType === 2 || answer.answerType === 3
                        "
                        :active="answer.answerObj.type === 'video'"
                        @click="changeAnswerType('video', answer)"
                      >
                        <answer-video
                          :answer.sync="answer.answerObj"
                        ></answer-video>
                      </b-tab>
                      <b-tab
                        title="Webhook"
                        :disabled="
                          answer.answerType === 2 || answer.answerType === 3
                        "
                        :active="
                          answer.answerType === 6 &&
                          answer.answerObj.type === 'form'
                        "
                        @click="changeAnswerType('form', answer)"
                      >
                        <answer-webhook
                          v-model="answer.answerObj"
                          :webhooks="webhooksData"
                          :form-model="answer.formModel"
                          @use-form-model="
                            answer.formModel = JSON.stringify($event)
                          "
                        ></answer-webhook>
                      </b-tab>
                      <b-tab
                        title="Webhook (legacy)"
                        :disabled="
                          !(answer.answerType === 2 || answer.answerType === 3)
                        "
                        :active="
                          answer.answerType === 2 || answer.answerType === 3
                        "
                        @click="changeAnswerType('form', answer)"
                      >
                        <answer-legacy-webhook></answer-legacy-webhook>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <section
                    v-show="
                      !(answer.answerType === 2 || answer.answerType === 3)
                    "
                  >
                    <div
                      class="form-group clickable"
                      v-b-toggle="'IA_' + index"
                    >
                      <span><b-icon-caret-right-fill /> Advanced Settings</span>
                      <small class="text-muted">(optional)</small>
                    </div>
                    <b-collapse :id="'IA_' + index" class="pt-1 px-3">
                      <b-row class="form-group">
                        <b-col cols="12" md="6" lg="3">
                          <label class="control-label">Answer Model</label>
                        </b-col>
                        <b-col>
                          <b-form-input
                            v-model="answer.answerModel"
                            size="sm"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col cols="12" md="6" lg="3">
                          <label class="control-label">Form Model</label>
                        </b-col>
                        <b-col>
                          <b-form-input v-model="answer.formModel" size="sm" />
                        </b-col>
                      </b-row>
                      <b-row class="form-group">
                        <b-col cols="12" md="6" lg="3">
                          <label class="control-label">Weight (1-5)</label>
                        </b-col>
                        <b-col cols="1">
                          <b-form-input
                            type="number"
                            min="0"
                            max="5"
                            v-model="answer.weight"
                            size="sm"
                            class="d-inline-block"
                          />
                        </b-col>
                      </b-row>
                    </b-collapse>
                  </section>
                </b-card>
              </div>
              <div class="form-group">
                <b-button
                  variant="light"
                  class="text-primary border-primary"
                  size="sm"
                  @click="addIntentAnswer"
                >
                  <b-icon-plus />
                  Add Answer
                </b-button>
              </div>
            </b-form>
          </b-tab>
        </b-tabs>
      </div>
    </b-overlay>

    <b-modal scrollable hide-footer>
      <b-overlay :show="isLoadingSuggestions">
        <b-row class="form-group">
          <b-col>
            <b-input-group>
              <b-form-input
                v-model="questionText"
                placeholder="search term"
                autofocus
                size="sm"
              />
              <b-input-group-append>
                <b-button
                  size="sm"
                  variant="outline-info"
                  @click="getSuggestions"
                  >search
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
        <b-row v-if="questionSuggestions.length" class="form-group">
          <b-col>
            <small class="text-muted">suggested questions:</small>
          </b-col>
        </b-row>
        <b-row v-for="suggestion in questionSuggestions" :key="suggestion">
          <b-col>
            <b-form-checkbox
              :value="true"
              :unchecked-value="false"
              @change="
                (checked) => {
                  if (checked) addQuestion(suggestion);
                  else removeQuestion(suggestion);
                }
              "
            >
              {{ suggestion }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';
import CustomValues from '@/components/Intents/CustomValues';
import AnswerText from '@/components/Intents/AnswerTypeText';
import AnswerImage from '@/components/Intents/AnswerTypeImage';
import AnswerVideo from '@/components/Intents/AnswerTypeVideo';
import AnswerWebhook from '@/components/Intents/AnswerTypeWebhook';
import AnswerLegacyWebhook from '@/components/Intents/AnswerTypeLegacyWebhook';

export default {
  name: 'IntentForm',
  mixins: [HttpHandler],
  components: {
    AnswerText,
    AnswerImage,
    AnswerVideo,
    AnswerWebhook,
    AnswerLegacyWebhook,
    CustomValues,
  },
  props: {
    selectedBotId: Number,
    selectedIntentCategoryId: Number,
    selectedIntent: Object,
    intentCategories: Array,
    intents: Array,
    webhooks: Array,
  },
  data() {
    return {
      formItem: {},
      questionSearchMode: false,
      questionText: null,
      questionSuggestions: [],
      isLoadingForm: false,
      isLoadingSuggestions: false,
    };
  },
  mounted() {
    // this.getSuggestions = _.debounce(this.getSuggestions, 200);
    this.loadIntent();
  },
  watch: {
    selectedIntent() {
      this.loadIntent();
    },
  },
  computed: {
    webhooksData() {
      try {
        return _.map(this.webhooks, function (webhook) {
          let data = _.pick(webhook, ['webhookId', 'webhookName']);
          let webhookData = JSON.parse(webhook.webhookData);
          return {
            text: data.webhookName,
            value: data.webhookId,
            formModel: webhookData.output
              ? webhookData.output.formModel || []
              : [],
          };
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        return [];
      }
    },
    sentenceBadges() {
      let list = _.filter(this.formItem.intentTrainings, (intentTraining) => {
        return !!intentTraining.sentence;
      });
      return _.map(list, (item) => {
        return item.sentence;
      });
    },
    answerBadges() {
      return _.map(this.formItem.intentAnswers, (answer) => {
        return answer.answerObj.type === 'form'
          ? 'webhook'
          : answer.answerObj.type;
      });
    },
  },
  methods: {
    displayQuestionScore(score) {
      const floatScore = parseFloat(score);
      return isNaN(floatScore) ? '' : floatScore.toFixed(1);
    },
    loadIntent() {
      const intentId = this.selectedIntent.intentId;
      if (intentId) {
        this.isLoadingForm = true;
        this.httpclient
          .get('/api/intents/' + intentId)
          .then((resp) => {
            this.formItem = {};
            return resp;
          })
          .then((resp) => {
            let formItem = resp.data;
            if (formItem.intentAnswers) {
              _.each(formItem.intentAnswers, (intentAnswer) => {
                intentAnswer.answerObj = JSON.parse(intentAnswer.answer);
                const tabIndexes = ['text', 'image', 'video', 'form'];
                intentAnswer.tabIndex = tabIndexes.indexOf(
                  intentAnswer.answerObj.type
                );
              });
            }

            formItem.intentTrainings = _.sortBy(
              formItem.intentTrainings,
              ['sentenceScore'],
              ['asc']
            );

            if (formItem.customValues) {
              let customObj = JSON.parse(formItem.customValues);
              if (_.isObject(customObj) && !_.isArray(customObj)) {
                let customValues = [];
                _.forEach(customObj, (value, key) => {
                  customValues.push({ key: key, value: value });
                });
                formItem.customValues = customValues || [];
              } else {
                formItem.customValues = [];
              }
            } else {
              formItem.customValues = [];
            }
            //use empty customValues cause old intent doesnt have this
            this.formItem = { ...formItem };
            this.formOptions = [];
          })
          .then(() => {
            this.isLoadingForm = false;
          });
      } else {
        this.formItem = {
          ..._defaultFormItem(),
          ...{ intentCategoryId: this.selectedIntentCategoryId },
        };
      }
    },
    getSuggestions() {
      this.isLoadingSuggestions = true;
      this.httpclient
        .get('api/bots/' + this.selectedBotId + '/question-recommendation', {
          params: { question: this.questionText },
        })
        .then((resp) => {
          let suggestions = [];
          if (
            this.questionText &&
            !_.some(this.formItem.intentTrainings, {
              sentence: this.questionText,
            })
          ) {
            suggestions = [...[this.questionText]];
          }

          if (resp.data) {
            suggestions = [
              ...suggestions,
              ..._.filter(resp.data, (v) => {
                return (
                  v !== this.questionText &&
                  !_.some(this.formItem.intentTrainings, {
                    sentence: v,
                  })
                );
              }),
            ];
          }
          this.questionSuggestions = suggestions;
        })
        .then(() => {
          this.isLoadingSuggestions = false;
        });
    },
    addQuestion(question) {
      if (!_.find(this.formItem.intentTrainings, { sentence: question })) {
        const newList = _.filter(
          this.formItem.intentTrainings,
          (intentTraining) => {
            return intentTraining.sentence !== '';
          }
        );
        this.formItem.intentTrainings = [
          ...newList,
          ...[{ sentence: question }],
        ];
      }
      this.questionSuggestions = _.filter(
        this.questionSuggestions,
        (suggestion) => {
          return suggestion !== question;
        }
      );
    },
    resetQuestions() {
      this.questionSearchMode = false;
    },
    removeQuestion(question) {
      if (_.find(this.formItem.intentTrainings, { sentence: question })) {
        const newList = _.filter(
          this.formItem.intentTrainings,
          (intentTraining) => {
            return intentTraining.sentence !== question;
          }
        );
        if (newList.length > 0) {
          this.formItem.intentTrainings = newList;
        } else {
          this.formItem.intentTrainings = [{ sentence: '' }];
        }
      }
    },
    addIntentAnswer() {
      this.formItem.intentAnswers.push(_defaultIntentAnswer());
    },
    removeIntentAnswer(item) {
      const array = this.formItem.intentAnswers;
      const index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
      }
    },
    changeAnswerType(type, answer) {
      const answerObj = JSON.parse(answer.answer);

      if (type === 'text') {
        answer.answerType = 1;
        if (answerObj.type === 'text') {
          answer.answerObj = answerObj;
        }
      } else if (type === 'image') {
        answer.answerType = 4;
        if (answerObj.type === 'image') {
          answer.answerObj = answerObj;
        }
      } else if (type === 'video') {
        answer.answerType = 5;
        if (answerObj.type === 'video') {
          answer.answerObj = answerObj;
        }
      }
    },
    submit() {
      const mainForm = this.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        this.$refs['main-tab'].activate();
        return;
      }
      const questionsForm = this.$refs['questions-form'];
      if (!questionsForm.reportValidity()) {
        this.$refs['questions-tab'].activate();
        return;
      }
      const answersForm = this.$refs['answers-form'];
      if (!answersForm.reportValidity()) {
        this.$refs['answers-tab'].activate();
        return;
      }

      let formItem = _.cloneDeep(this.formItem);
      formItem.intentTrainings = _.map(
        formItem.intentTrainings,
        function (intentTraining) {
          return _.pick(intentTraining, ['sentence']);
        }
      );

      formItem.intentAnswerViewModels = _.map(
        formItem.intentAnswers,
        function (intentAnswer) {
          let a = _.pick(intentAnswer, [
            'answerType',
            'answer',
            'weight',
            'answerWebhookUrl',
            'answerModel',
            'formModel',
          ]);
          //if not legacy intent answer
          if (!(a.answerType === 2 || a.answerType === 3)) {
            a.answer = JSON.stringify(
              _.omit(intentAnswer.answerObj, ['fileBytes'])
            );
            if (intentAnswer.answerObj.fileBytes) {
              a.fileName = intentAnswer.answerObj.fileName;
              a.fileBytes = intentAnswer.answerObj.fileBytes;
            }
            switch (intentAnswer.answerObj.type) {
              case 'text':
                a.answerType = 1;
                break;
              case 'image':
                a.answerType = 4;
                break;
              case 'video':
                a.answerType = 5;
                break;
              case 'form':
                a.answerType = 6;
                break;
            }
          }
          return a;
        }
      );

      formItem.intentAnswers = _.map(
        formItem.intentAnswers,
        function (intentAnswer) {
          let a = _.pick(intentAnswer, [
            'answerType',
            'answer',
            'weight',
            'answerWebhookUrl',
            'answerModel',
            'formModel',
          ]);
          //if not legacy intent answer
          if (!(a.answerType === 2 || a.answerType === 3)) {
            a.answer = JSON.stringify(
              _.omit(intentAnswer.answerObj, ['fileBytes'])
            );
            switch (intentAnswer.answerObj.type) {
              case 'text':
                a.answerType = 1;
                break;
              case 'image':
                a.answerType = 4;
                break;
              case 'video':
                a.answerType = 5;
                break;
              case 'form':
                a.answerType = 6;
                break;
            }
          }
          return a;
        }
      );

      //Process Custom Values
      let customObj = _.reduce(
        formItem.customValues,
        (obj, { key, value }) => ({ ...obj, [key]: value }),
        {}
      );
      formItem.customValues = JSON.stringify(customObj);

      //is edit
      if (this.formItem.intentId) {
        this.$bvModal
          .msgBoxConfirm('Proceed to edit the Intent?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              return this.httpclient
                .put('api/intents', { ...formItem, botId: this.selectedBotId })
                .then(() => {
                  this.$bvModal
                    .msgBoxOk(
                      'The Intent, [' +
                        formItem.intentName +
                        '] had been edited successfully.',
                      {
                        okVariant: 'success',
                        centered: true,
                      }
                    )
                    .then(() => {
                      this.$emit('edited');
                    });
                });
            }
          });
        //is add
      } else {
        this.$bvModal
          .msgBoxConfirm('Proceed to add the Intent?', { centered: true })
          .then((confirm) => {
            if (confirm) {
              return this.httpclient
                .post('api/intents', { ...formItem, botId: this.selectedBotId })
                .then(() => {
                  this.$bvModal
                    .msgBoxOk(
                      'The Intent, [' +
                        formItem.intentName +
                        '] had been added successfully.',
                      {
                        okVariant: 'success',
                        centered: true,
                      }
                    )
                    .then(() => {
                      this.$emit('created');
                    });
                });
            }
          });
      }
    },
  },
};

function _defaultFormItem() {
  return {
    fileName: null,
    fileBytes: null,
    intentName: '',
    intentType: 'user',
    requireAuthentication: false,
    notifyAgent: false,
    customValues: [],
    intentTrainings: [
      {
        sentence: '',
      },
    ],
    intentAnswers: [
      {
        answerType: 1,
        answer: '{"type": "text", "content": {"links": [], "message": ""}}',
        weight: 1,
        answerWebhookUrl: null,
        answerModel: null,
        formModel: null,
        answerObj: {
          type: 'text',
          content: {},
        },
        tabIndex: 0,
      },
    ],
  };
}

function _defaultIntentAnswer() {
  return {
    answerType: 1,
    answer: '{"type": "text", "content": {"links": [], "message": ""}}',
    weight: 1,
    answerWebhookUrl: null,
    answerModel: null,
    formModel: null,
    answerObj: {
      type: 'text',
      content: {},
    },
    tabIndex: 0,
  };
}
</script>

<style scoped>
.search-question-container {
  border-left: #ced4da solid 1px;
}

.search-question-panel {
  min-height: 300px;
}

.info-badge {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
