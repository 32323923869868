<template>
  <div class="d-inline-block">
    <b-button
      class="list-button"
      variant="outline-primary"
      size="sm"
      @click="getActivityLogs"
      title="show history"
    >
      <b-icon icon="clock-history" class="list-button" />
    </b-button>
    <b-modal v-model="modalList" size="lg" centered scrollable hide-footer>
      <b-table-lite
        v-if="list.length"
        :items="items"
        :fields="fields"
        bordered
        striped
      >
        <template #cell(actionDatetime)="row">
          {{ row.value | datetimelong }}
        </template>
        <template #cell(showDetails)="row">
          <b-button
            v-if="row.item.action === 'Edit'"
            size="sm"
            @click="row.toggleDetails"
            class="mr-2"
          >
            {{ row.detailsShowing ? 'Hide' : 'Show' }} Details
          </b-button>
        </template>
        <template #row-details="row">
          <b-row v-for="change in row.item.comparedObj" :key="change.key">
            <b-col>
              <small class="mr-2 d-block">{{ change.key }}</small>
              <small class="mr-2 text-warning">[{{ change.old }}]</small>
              <b-icon-arrow-right class="mr-2" />
              <small class="mr-2 text-success">[{{ change.new }}]</small>
            </b-col>
          </b-row>

          <div
            v-if="
              row.item.intentTrainings.removed.length ||
              row.item.intentTrainings.added.length
            "
          >
            <div><small>Questions</small></div>
            <div><small class="text-muted pl-2">added:</small></div>
            <div>
              <small
                class="d-block text-success pl-3"
                v-for="sentence in row.item.intentTrainings.added"
                :key="sentence"
              >
                {{ sentence }}
              </small>
            </div>
            <div><small class="text-muted pl-2">removed:</small></div>
            <div>
              <small
                class="d-block text-warning pl-3"
                v-for="sentence in row.item.intentTrainings.removed"
                :key="sentence"
              >
                {{ sentence }}
              </small>
            </div>
          </div>

          <div
            v-if="
              row.item.intentAnswers.removed.length ||
              row.item.intentAnswers.added.length
            "
          >
            <div><small>Answers</small></div>
            <div><small class="text-muted pl-2">added:</small></div>
            <div>
              <small
                class="d-block text-success pl-3"
                v-for="answer in row.item.intentAnswers.added"
                :key="JSON.stringify(answer)"
              >
                <json-viewer :value="answer" />
              </small>
            </div>
            <div><small class="text-muted pl-2">removed:</small></div>
            <div>
              <small
                class="d-block text-warning pl-3"
                v-for="answer in row.item.intentAnswers.removed"
                :key="JSON.stringify(answer)"
              >
                <json-viewer :value="answer" />
              </small>
            </div>
          </div>
        </template>
      </b-table-lite>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';
import _ from 'lodash';
import JsonViewer from '@/components/UI/JsonViewer';

export default {
  name: 'IntentActivityLog',
  props: {
    intentId: Number,
  },
  components: { JsonViewer },
  data() {
    return {
      list: [],
      modalList: false,
      fields: [
        {
          key: 'actionDatetime',
          label: 'Datetime',
        },
        {
          key: 'userName',
          label: 'Username',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'showDetails',
          label: '',
        },
      ],
    };
  },
  computed: {
    items() {
      let list = _.map(this.list, (log) => {
        let logObj = _.pick(log, [
          'actionDatetime',
          'userName',
          'action',
          'data',
        ]);
        const dataObj = JSON.parse(logObj.data);
        const propsToBeOmitted = [
          'IntentAnswerViewModels',
          'IntentAnswers',
          'IntentTrainings',
          'IsDeleted',
          'CreatedAt',
          'CreatedBy',
          'EditedAt',
          'EditedBy',
          'FileBytes',
          'FileNames',
        ];
        let oldObj = { ...dataObj.Old };
        let newObj = { ...dataObj.New };
        let compareObj = _.omit(oldObj, propsToBeOmitted);
        let comparedObj = [];
        _.each(compareObj, (value, key) => {
          let oldValue = value;
          let newValue = newObj[key];
          //handle case when it is json string
          if (key === 'CustomValues') {
            oldValue = JSON.stringify(JSON.parse(oldValue));
            newValue = JSON.stringify(JSON.parse(newValue));
          }

          if (oldValue !== newValue) {
            comparedObj.push({ key: key, old: oldValue, new: newValue });
          }
        });
        logObj.comparedObj = comparedObj;

        logObj.intentTrainings = {
          removed: [],
          added: [],
        };
        let oldSentences = _.map(oldObj.IntentTrainings, 'Sentence');
        let newSentences = _.map(newObj.IntentTrainings, 'Sentence');
        _.each(oldSentences, (o) => {
          if (!newSentences.includes(o)) {
            logObj.intentTrainings.removed.push(o);
          }
        });
        _.each(newSentences, (o) => {
          if (!oldSentences.includes(o)) {
            logObj.intentTrainings.added.push(o);
          }
        });

        logObj.intentAnswers = {
          removed: [],
          added: [],
        };
        let oldAnswers = [...oldObj.IntentAnswers];
        let newAnswers = [...newObj.IntentAnswers];
        _.each(oldAnswers, (o) => {
          if (
            !_.some(newAnswers, (n) => {
              return (
                JSON.stringify(_.omit(JSON.parse(n.Answer), ['fileName'])) ===
                  JSON.stringify(_.omit(JSON.parse(o.Answer), ['fileName'])) &&
                n.Weight === o.Weight &&
                n.AnswerWebhookUrl === o.AnswerWebhookUrl &&
                JSON.stringify(JSON.parse(n.FormModel)) ===
                  JSON.stringify(JSON.parse(o.FormModel)) &&
                JSON.stringify(JSON.parse(n.AnswerModel)) ===
                  JSON.stringify(JSON.parse(o.AnswerModel))
              );
            })
          ) {
            logObj.intentAnswers.removed.push(
              _.pick(o, [
                'Answer',
                'Weight',
                'AnswerWebhookUrl',
                'FormModel',
                'AnswerModel',
              ])
            );
          }
        });

        _.each(newAnswers, (n) => {
          if (
            !_.some(oldAnswers, (o) => {
              return (
                JSON.stringify(_.omit(JSON.parse(n.Answer), ['fileName'])) ===
                  JSON.stringify(_.omit(JSON.parse(o.Answer), ['fileName'])) &&
                n.Weight === o.Weight &&
                n.AnswerWebhookUrl === o.AnswerWebhookUrl &&
                JSON.stringify(JSON.parse(n.FormModel)) ===
                  JSON.stringify(JSON.parse(o.FormModel)) &&
                JSON.stringify(JSON.parse(n.AnswerModel)) ===
                  JSON.stringify(JSON.parse(o.AnswerModel))
              );
            })
          ) {
            logObj.intentAnswers.added.push(
              _.pick(n, [
                'Answer',
                'Weight',
                'AnswerWebhookUrl',
                'FormModel',
                'AnswerModel',
              ])
            );
          }
        });

        return logObj;
      });
      return list;
    },
  },
  mixins: [HttpHandler],
  methods: {
    async getActivityLogs() {
      let resp = await this.httpclient.get(
        `api/intents/${this.intentId}/intent-activity-logs`
      );
      this.list = resp.data;
      this.modalList = true;
    },
  },
};
</script>

<style scoped>
.list-button {
  padding: 1px 3px;
}

.list-button:hover {
  color: #fff;
}

/*.list-button:hover {*/
/*  border: 1px solid #00b6f0;*/
/*  border-radius: 2px;*/
/*  padding: 2px;*/
/*}*/
</style>
