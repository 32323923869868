<template>
  <div>
    <b-row>
      <b-col>
        <div
          v-for="intentCategory in sortedIntentCategories"
          :key="intentCategory.intentCategoryId"
        >
          <intent-category-list-item
            :intent-category="intentCategory"
            @selected="select"
            @remove="remove"
            @add="add"
            @edit="edit"
          />
        </div>
      </b-col>
    </b-row>

    <b-modal v-model="formModal" hide-header hide-footer centered>
      <intent-category-form
        :bot-id="botId"
        :parents="parents"
        :intent-category="formItem"
        @cancel="formModal = false"
        @done="
          getList();
          formModal = false;
        "
      />
    </b-modal>
  </div>
</template>

<script>
/*global _*/
import httpclient from '@/mixins/HttpHandler';
import IntentCategoryListItem from '@/components/Intents/IntentCategoryListItem';
import IntentCategoryForm from '@/components/Intents/IntentCategoryForm';

export default {
  name: 'IntentCategoryList',
  props: { botId: { type: Number, default: null } },
  mixins: [httpclient],
  components: { IntentCategoryListItem, IntentCategoryForm },
  data() {
    return {
      intentCategories: [],

      formModal: false,
      formItem: {},
      isEdit: false,
    };
  },
  mounted() {
    if (this.botId) {
      this.getList().then(() => {
        this.select(this.sortedIntentCategories[0]);
      });
    }
  },
  watch: {
    botId() {
      if (this.botId) {
        this.getList().then(() => {
          this.select(this.sortedIntentCategories[0]);
        });
      }
    },
  },
  computed: {
    sortedIntentCategories() {
      try {
        if (this.intentCategories.length > 0) {
          const list = _.sortBy(this.intentCategories, ['parentId']);
          const root = _.find(list, { parentId: null });
          return _getNestedList(root, list);
        } else {
          return [];
        }
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.log(ex);
        return [];
      }
    },
    parents() {
      let options = [];
      const flatten = function (list, padding) {
        _.forEach(list, function (item) {
          options.push({
            value: item.intentCategoryId,
            html:
              (padding ? padding + '<b>&gt;</b>&nbsp;' : '') +
              item.intentCategoryName,
          });
          if (item.child) {
            flatten(item.child, (padding || '') + '&ensp;');
          }
        });
      };
      flatten(this.sortedIntentCategories);
      return options;
    },
  },
  methods: {
    getList() {
      this.intentCategories = [];
      const botId = this.botId;
      return this.httpclient
        .get('api/intentCategories', { params: { botId: botId } })
        .then((resp) => {
          if (resp.data) {
            if (this.botId === botId) this.intentCategories = resp.data;
          }
        })
        .then(() => {
          if (this.botId === botId) this.$emit('listLoaded', this.parents);
        });
    },
    remove(item) {
      this.$bvModal
        .msgBoxConfirm(
          'Proceed to remove the Intent Category? This will remove the Intent Category and all its intents. There is no way to recover the deleted intents.',
          {
            centered: true,
            bodyTextVariant: 'danger',
            okTitle: 'Remove (all intents)',
            okVariant: 'danger',
          }
        )
        .then((value) => {
          if (value) {
            this.httpclient
              .delete('api/intentcategories/' + item.intentCategoryId)
              .then(() => {
                this.getList();
                this.$bvModal.msgBoxOk(
                  'The Intent Category,"' +
                    item.intentCategoryName +
                    '" had been removed successfully.',
                  {
                    okVariant: 'success',
                    centered: true,
                  }
                );
              })
              .then(function () {
                this.modal = false;
              });
          }
        });
    },
    add(item) {
      this.isEdit = false;
      this.formModal = true;
      this.formItem = {
        intentCategoryId: null,
        IntentCategoryName: '',
        parentId: item.intentCategoryId,
      };
    },
    edit(item) {
      this.isEdit = true;
      this.formModal = true;
      this.formItem = item;
    },
    select(item) {
      this.$emit('selected', _.omit(item, ['child']));
    },
  },
};

function _getNestedList(root, adjacencyList) {
  const added = {};
  const rootNode = _.cloneDeep(root);
  const SetDepth = function (currentNode) {
    added[currentNode.intentCategoryId] = true;
    while (
      _.some(adjacencyList, function (node) {
        return (
          node.parentId === currentNode.intentCategoryId &&
          !added[node.intentCategoryId]
        );
      })
    ) {
      let childNode = _.find(adjacencyList, function (node) {
        return (
          node.parentId === currentNode.intentCategoryId &&
          !added[node.intentCategoryId]
        );
      });
      if (!Object.prototype.hasOwnProperty.call(currentNode, 'child')) {
        currentNode.child = [];
      }
      if (
        !_.some(currentNode.child, {
          intentCategoryId: childNode.intentCategoryId,
        })
      ) {
        currentNode.child.push(childNode);
      }
      added[childNode.intentCategoryId] = true;
      SetDepth(childNode, adjacencyList);
    }
  };
  SetDepth(rootNode);
  const menu = [];
  menu.push(rootNode);
  return menu;
}
</script>

<style scoped></style>