<template>
  <div class="p-3">
    <b-row class="form-group">
      <b-col cols="12" md="4" lg="3">
        <label class="control-label" for="imageUrl">Video Url</label>
      </b-col>
      <b-col>
        <b-form-input
          id="imageUrl"
          type="url"
          v-model="answerObj.content.url"
          size="sm"
          required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /*global _*/
  props: ['answer'],
  data() {
    return {
      answerObj: {
        type: 'video',
        content: {
          url: 'https://',
        },
      },
      defaultAnswer: {
        type: 'video',
        content: {
          url: 'https://',
        },
      },
    };
  },
  mounted() {
    if (this.answer.type === 'video') {
      this.answerObj = this.answer;
    } else {
      this.answerObj = _.cloneDeep(this.defaultAnswer);
    }
  },
  watch: {
    answer(newVal) {
      if (newVal.type === 'video') {
        this.answerObj = newVal;
      } else {
        this.answerObj = _.cloneDeep(this.defaultAnswer);
      }
    },
    'answerObj.content.url': function () {
      this.$emit('update:answer', {
        type: 'video',
        content: {
          url: this.answerObj.content.url,
        },
      });
    },
  },
};
</script>

<style>
.trumbowyg-box {
  margin: 0 !important;
}
</style>
