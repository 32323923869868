<template>
  <div>
    <div class="intent-category d-inline-block">
      <b-icon
        class="intent-category-head"
        icon="circle-fill"
        :variant="iconVariant"
        shift-v="6"
      ></b-icon>
      <span @click="select(intentCategory)" class="selectable"
        >{{ level === 1 ? 'All' : intentCategory.intentCategoryName }}
        <small
          class="text-muted"
          v-if="level !== 1 && intentCategory.intentCount"
          >({{ intentCategory.intentCount }})</small
        ></span
      >
      <b-icon
        class="intent-category-icon border rounded"
        icon="plus-square"
        variant="primary"
        title="add"
        @click="add(intentCategory)"
      ></b-icon>
      <b-icon
        v-if="level > 1"
        class="intent-category-icon border rounded"
        icon="dash-square"
        variant="danger"
        title="remove"
        @click="remove(intentCategory)"
      ></b-icon>
      <b-icon
        v-if="level > 1"
        class="intent-category-icon border rounded"
        icon="pencil-square"
        variant="info"
        title="edit"
        @click="edit(intentCategory)"
      ></b-icon>
    </div>
    <div class="intent-category-children" v-if="intentCategory.child">
      <div v-for="child in intentCategory.child" :key="child.intentCategoryId">
        <intent-category-list-item
          :intent-category="child"
          :level="level + 1"
          @selected="select"
          @remove="remove"
          @add="add"
          @edit="edit"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IntentCategoryListItem',
  props: {
    intentCategory: {
      type: Object,
      default() {
        return {
          intentCategoryId: -1,
          intentCategoryName: 'Loading...',
          child: null,
        };
      },
    },
    level: { type: Number, default: 1 },
  },
  computed: {
    iconVariant() {
      const variants = [
        'secondary',
        'primary',
        'info',
        'success',
        'warning',
        'secondary',
      ];
      return variants[this.level - 1];
    },
  },
  methods: {
    add(item) {
      this.$emit('add', item);
    },
    remove(item) {
      this.$emit('remove', item);
    },
    edit(item) {
      this.$emit('edit', item);
    },
    select(item) {
      this.$emit('selected', item);
    },
  },
};
</script>

<style scoped>
.intent-category-head {
  font-size: 0.5em !important;
  margin-right: 1em;
}

.intent-category-children {
  padding: 3px 3px 3px 12px;
}

.intent-category:hover .intent-category-icon {
  visibility: visible;
}

.intent-category-icon {
  font-size: 1.1em;
  visibility: hidden;
  margin: 2px 0 0 5px;
  z-index: 100;
}

.intent-category-icon:hover {
  font-weight: bold;
  cursor: pointer;
  background-color: #ebebeb !important;
}

.selectable {
  cursor: pointer;
}

.selectable:hover {
  color: #0b93d5;
}
</style>
