<template>
  <div>
    <b-row v-if="trees">
      <b-col>
        <node-tree
          :nodes="trees"
          :intentId="intentId"
          @node-clicked="nodeClicked"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="text-center" style="width: 100%; height: 10vh">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="align-bottom"
          ></b-spinner>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import httpclient from '@/mixins/HttpHandler';
import NodeTree from '@/components/UI/NodeTree/NodeTree';

export default {
  name: 'IntentMapping',
  components: { NodeTree },
  props: { trees: Array },
  mixins: [httpclient],
  data() {
    return {
      intentId: 0,
    };
  },
  methods: {
    nodeClicked(e, data) {
      this.$emit('intentSelected', { intentId: data.intentId });
    },
  },
};
</script>

<style scoped>
.tree-container {
  /*display: inline-flex;*/
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  height: calc(100vh - 180px);
}
</style>
