<template>
  <div>
    <b-row class="form-group">
      <b-col cols="3">
        <label>Webhook</label>
      </b-col>
      <b-col>
        <b-select
          v-model="value.webhookId"
          :options="webhooks"
          size="sm"
          required
        ></b-select>
      </b-col>
    </b-row>
    <b-row v-if="selectedWebhook.formModel.length > 0" class="form-group">
      <b-col cols="3"> </b-col>
      <b-col v-if="JSON.stringify(selectedWebhook.formModel) != formModel">
        <div class="mb-2">
          <small class="text-muted"
            >(Optional) This webhook contain form model settings. Click the
            button below to use the settings.</small
          >
        </div>
        <b-button @click="UseFormModel" size="sm" variant="secondary"
          >Use FormModel</b-button
        >
      </b-col>
      <b-col
        v-else-if="JSON.stringify(selectedWebhook.formModel) === formModel"
      >
        <small class="text-muted">
          <b-icon-info-circle variant="info" />
          Using the form model settings if this webhook</small
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  /*global _*/
  props: ['value', 'webhooks', 'formModel'],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    selectedWebhook() {
      return (
        _.find(this.webhooks, { value: this.value.webhookId }) || {
          value: -1,
          text: '',
          formModel: [],
        }
      );
    },
  },
  watch: {
    value: {
      handler(newVal) {
        newVal.type = 'form';
        this.$emit('input', newVal);
      },
      deep: true,
    },
  },
  methods: {
    UseFormModel() {
      this.$emit('use-form-model', this.selectedWebhook.formModel);
    },
  },
};
</script>

<style>
.trumbowyg-box {
  margin: 0 !important;
}

.webhook-tag {
  background-color: #ffffff;
  border: #ffffff;
}

.webhook-tag:focus {
  outline: none;
}
</style>
