<template>
  <div class="d-inline-block">
    <b-button
      class="list-button"
      variant="outline-primary"
      size="sm"
      @click="getRecentActivityLogs"
      title="show history"
    >
      <b-icon icon="clock-history" class="list-button" />
    </b-button>
    <b-modal v-model="modalList" size="lg" centered scrollable hide-footer>
      <b-table-lite
        v-if="list.length"
        :items="items"
        :fields="fields"
        bordered
        striped
      >
        <template #cell(actionDatetime)="row">
          {{ row.value | datetimelong }}
        </template>
        <template #cell(itemId)="row">
          <span
            class="text-primary item-id-button"
            @click="selectIntentId(row.value)"
            >{{ row.value }}</span
          >
        </template>
      </b-table-lite>
      <span v-else>There is no recent history</span>
    </b-modal>
  </div>
</template>

<script>
import HttpHandler from '@/mixins/HttpHandler';

export default {
  name: 'IntentRecentActivityLog',
  props: {
    botId: Number,
  },
  data() {
    return {
      list: [],
      modalList: false,
      fields: [
        {
          key: 'actionDatetime',
          label: 'Datetime',
        },
        {
          key: 'userName',
          label: 'Username',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'itemId',
          label: 'Intent ID',
        },
      ],
    };
  },
  computed: {
    items() {
      return this.list;
    },
  },
  mixins: [HttpHandler],
  methods: {
    async getRecentActivityLogs() {
      let resp = await this.httpclient.get(
        `api/bots/${this.botId}/recent-intent-activity-logs`
      );
      this.list = resp.data;
      this.modalList = true;
    },
    selectIntentId(id) {
      this.modalList = false;
      this.$emit('selected', id);
    },
  },
};
</script>

<style scoped>
.list-button {
  padding: 1px 3px;
}

.item-id-button {
  cursor: pointer;
}

.item-id-button:hover {
  text-decoration: underline;
}
</style>
