<template>
  <div>
    <b-row>
      <b-col>
        <span class="form-header">
          {{ formItem.intentCategoryId ? 'Edit' : 'Add' }}
        </span>
      </b-col>
    </b-row>
    <hr />
    <b-form ref="main-form" @submit.prevent.stop="submit">
      <b-row class="mb-3">
        <b-col cols="4"><label size="sm">Parent</label></b-col>
        <b-col cols="8">
          <b-form-select
            size="sm"
            v-model="formItem.parentId"
            :options="parents"
          ></b-form-select>
        </b-col>
      </b-row>
      <b-row class="mb-3">
        <b-col cols="4"><label size="sm">Category Name</label></b-col>
        <b-col cols="8">
          <b-form-input
            size="sm"
            class=""
            v-model="formItem.intentCategoryName"
            required
          />
        </b-col>
      </b-row>
    </b-form>
    <hr />
    <b-row>
      <b-col>
        <b-button
          size="sm"
          class="float-right ml-3"
          variant="primary"
          @click="submit"
          >Submit
        </b-button>
        <b-button size="sm" class="float-right" @click="$emit('cancel')"
          >Cancel
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/*global _*/
import httpclient from '@/mixins/HttpHandler';

export default {
  name: 'IntentCategoryForm',
  props: {
    botId: { type: Number, default: null },
    parents: {
      type: Array,
      default() {
        return [{ value: null, html: 'Select' }];
      },
    },
    intentCategory: {
      type: Object,
      default() {
        return {
          intentCategoryId: -1,
          IntentCategoryName: '',
          parentId: this.parents[0].value,
        };
      },
    },
  },
  mixins: [httpclient],
  mounted() {
    this.formItem = this.intentCategory;
  },
  watch: {
    intentCategory(newValue) {
      this.formItem = newValue;
    },
  },
  data() {
    return {
      formItem: {},
    };
  },
  methods: {
    submit() {
      const mainForm = this.$refs['main-form'];
      if (!mainForm.reportValidity()) {
        return;
      }
      const formItem = { ...this.formItem };
      const isEdit = Boolean(formItem.intentCategoryId);
      const message =
        'Proceed to ' + (isEdit ? 'edit' : 'add') + ' the Intent Category?';

      this.$bvModal.msgBoxConfirm(message, { centered: true }).then((value) => {
        if (value) {
          if (isEdit) {
            const cat = _.pick(this.formItem, [
              'intentCategoryId',
              'intentCategoryName',
              'parentId',
            ]);
            this.httpclient
              .put('api/intentCategories', { ...cat, botId: this.botId })
              .then(() => {
                this.$bvModal
                  .msgBoxOk(
                    'The Intent Category, [' +
                      formItem.intentCategoryName +
                      '] had been edited successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  )
                  .then(() => {
                    this.$emit('done');
                  });
              });
          } else {
            const cat = _.pick(formItem, ['intentCategoryName', 'parentId']);
            this.httpclient
              .post('api/intentCategories', { ...cat, botId: this.botId })
              .then(() => {
                this.$bvModal
                  .msgBoxOk(
                    'The Intent Category, [' +
                      formItem.intentCategoryName +
                      '] had been added successfully.',
                    {
                      okVariant: 'success',
                      centered: true,
                    }
                  )
                  .then(() => {
                    this.$emit('done');
                  });
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.form-header {
  /*margin-left: 1em;*/
  font-size: 1.2em;
}
</style>